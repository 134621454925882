<template>
  <div class="wallets">
    <left-side-bar/>
    <!-- wallets section-->
    <section class="wallets-section">
      <top-navbar />
      <p>Wallets</p>
      <p>To get more information on crypto wallets, click <a>here</a>.</p>

      <div class="wallet-list"
           v-for="(item, i) in items"
           :key="i">
        <div>
          <img :src="require(`@/assets/images/logo/${item.icon}-logo.svg`)" alt="crypto-logo"
               width="16" height="16"/>
          <div>
            <p>{{ item.title }}</p>
            <p>{{ item.address }}</p>
          </div>
        </div>
        <v-badge color="#DCDCDC" dot
        ></v-badge>
      </div>
      <button><img src="@/assets/images/icons/metamask.svg" alt="metamask" width="20" height="20"/><span>CONNECT METAMASK</span>
      </button>
    </section>
  </div>
</template>

<script>
import LeftSideBar from "./LeftSidebar";
import TopNavbar from "@/views/User/Settings/TopNavbar";

export default {
  name: 'Wallets',
  components: {
    TopNavbar,
    LeftSideBar
  },
  data() {
    return {
      items: [
        {title: 'Ethereum', icon: 'eth', address: '0XB66E...4EC2'},
        {title: 'Polygon', icon: 'matic', address: '0XB66E...4EC2'},
      ],
    }
  },
  methods: {}
}
</script>